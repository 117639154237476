
import { ref } from 'vue';
import { cloneDeep } from 'lodash';
import { IProposalStatus } from '@/types/Interfaces';
import { client } from './Axios';

const cloneStatus = async (sourcePurposeId: number, targetPurposeId: number) => {
  const payload: any = {
    source_purpose_id: sourcePurposeId,
    target_purpose_id: targetPurposeId
  }
  const {data} = await client.post('/purposes/clone/', payload)
  console.log(data)
  return data;
}
const fetchProposalStatus = async (): Promise<IProposalStatus[]> => {
  const {data} = await client.get(`/proposal_statuses/`);
  return data;
};

const createProposalStatus = async (payload: IProposalStatus) => {
  const {data} = await client.post('/proposal_statuses/', payload)
  return data;
}

const updateProposalStatus = async (id: number, payload: IProposalStatus) => {
  const {data} = await client.patch(`/proposal_statuses/${id}/`, payload)
  return data;
}
const deleteProposalStatus = async (id: number) => {
  const {data} = await client.delete(`/proposal_statuses/${id}/`)
  return data;
}

const getChildren = (proposalStatusList: IProposalStatus[], status: IProposalStatus) => {
  return proposalStatusList.filter((d: any) => d.parent === status.tag);
};
const sortStatus = (data: any, purposeId: number) => {
  const copy = cloneDeep(data).filter((d: any) => d.purpose == purposeId || d.purpose_id == purposeId);
  const sorted = [];
  console.log(data)
  let currentTags: string[] | null = null;
  let loop = 1
  while (copy.length) {
    loop ++;
    if (loop > 100) break
    console.log('loop')
    let nextChildren: IProposalStatus[];
    if (currentTags == null) {
      nextChildren = copy.filter((d: IProposalStatus) => d.parent == null || d.parent == '');
      console.log(nextChildren)
    } else {
      nextChildren = [];
      for (const t of currentTags) {
        copy.filter((d: IProposalStatus) => d.parent == t).map((c: IProposalStatus) => {
          nextChildren.push(c)
        })
      }
      // alert(JSON.stringify(nextChildren))
    } 
    if (nextChildren.length == 0) break;
    // alert(JSON.stringify(nextChildren))
    sorted.push(nextChildren);
    currentTags = nextChildren.map((c: IProposalStatus) => c.tag);
    if (currentTags) {
      currentTags.map((tag: string) => {
        const removingIndex = copy.findIndex((d: IProposalStatus) => d.tag == tag );
        if (removingIndex >= 0) {
          copy.splice(removingIndex, 1);
        } else {
          console.log('hgoe')
        }
      });
    } else {
      break;
    }
  }
  return sorted;
};

export default function useProposalStatus() {
  return {
    cloneStatus,
    fetchProposalStatus,
    createProposalStatus,
    updateProposalStatus,
    deleteProposalStatus,
    sortStatus,
    getChildren
  }
}


import { ref } from 'vue';
import { cloneDeep } from 'lodash';
import { IPurpose } from '@/types/Interfaces';
import { client } from './Axios';

const fetchPurposes = async (): Promise<IPurpose[]> => {
  const {data} = await client.get('/purposes/');
  return data;
};

const createPurpose = async (payload: IPurpose) => {
  const {data} = await client.post('/purposes/', payload);
  return data;  
}

const updatePurpose = async (id: number, payload: IPurpose) => {
  const {data} = await client.patch(`/purposes/${id}/`, payload);
  return data;  
}

const deletePurpose = async (id: number) => {
  const {data} = await client.delete(`/purposes/${id}/`);
  return data;  
}


export default function usePurpose() {
  return {
    fetchPurposes,
    createPurpose,
    updatePurpose,
    deletePurpose,
  }
}
